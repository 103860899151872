<template>
  <div class="layout--full-page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    if (navigator.language == "tr") {
      this.$i18n.locale = navigator.language;
    } else {
      this.$i18n.locale = "en";
    }
  },
};
</script>
